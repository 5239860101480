import fetch from '../http.js'

/**
 * @description: 膝关节系统接口
 */

// 登录

export const login = data => fetch({
  url: '/api/admin/login',
  method: 'post',
  data
})
// 获取管理员用户信息
export const getInfo = params => fetch({
  url: '/api/admin/userInfo',
  method: 'get',
  params
})

// 完成新手引导
export const guide = data => fetch({
  url: '/api/guide/upload',
  method: 'post',
  data
})

// 查看案例
export const getSample = params => fetch({
  url: '/api/analyse/sample',
  method: 'get',
  params
})

// 文件解析
export const postAnalyseUpload = data => fetch({
  url: '/api/analyse/upload',
  method: 'post',
  data
})

// 查询解析进度
export const getAnalyseProgress = id => fetch({
  url: '/api/analyse/progress',
  method: 'get',
  params: { id }
})

// 获取dicom分析数据
export const getAnalyseResult = id => fetch({
  url: '/api/analyse/result',
  method: 'get',
  params: { id }
})
