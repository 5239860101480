<template>
  <header class="header">
    <div class="h-left">
      <icon-font
        class="mr-2"
        name="#mdd-fubianLOGO"
        :width="28"
        :height="28"
        color="#2B7FE2"
      ></icon-font>
      <h1>复变医疗·全膝关节置换术软组织平衡辅助决策系统</h1>
    </div>
    <div class="logout" >
      <icon-font
        class="mr-2"
        name="#mdd-touxiang"
        :width="15"
        :height="15"
        color="#ffffff"
      ></icon-font>
      <span>{{username}}，欢迎登录</span>
      <span style="padding-left:30px;color:#2B7FE2;cursor: pointer;" @click="logout()">退出</span>
    </div>

  </header>
</template>

<script>
import { postLogout } from '@/api/modules/login.js'
import { getInfo } from '@/api/modules/knee-system.js'
export default {
  name: 'KneeHeader',
  data () {
    return {
      username: ''
    }
  },
  mounted () {
    const res = this.$store.state.userName || ''
    if (res === '') {
      this.getUserInfo()
    } else {
      this.username = res
    }
  },
  methods: {
    async getUserInfo () {
      const { data } = await getInfo()
      this.username = data.userName
      this.$store.commit('SET_USER_NAME', this.username)
    },
    logout () {
      postLogout().then(res => {
        if (res.code === 0) {
          this.$message({
            message: '退出成功',
            type: 'success',
            duration: 1000
          })
          setTimeout(() => {
            window.location.href = '/knee-system?#/login'
          }, 1000)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  color: #fff;
  background:#001529;
  box-sizing: border-box;
  overflow: hidden;
}
.h-left {
  display: flex;
  align-items: center;
  h1 {
    margin-bottom: 0;
    font-size: 16px;
  }
}
.logout {
  z-index: inherit;
  font-size: 14px;
  display: flex;
  align-items: center;
}
</style>
